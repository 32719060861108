export default {
  meta_title: 'Learn quantum programming — PennyLane',
  meta_description:
    'The most comprehensive resource for learning about quantum computing, quantum machine learning (QML), and quantum chemistry, and practicing quantum programming.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/superhubOpenGraph.png',
  top_section: {
    title: 'Learn quantum programming with PennyLane',
    sub_title_1:
      'We have entered a new era of quantum computing, where increasingly advanced quantum devices are used to drive an active field of research and applications. Choose an area of interest to get started.',
    sub_title_2: '',
    link_cards: [
      {
        link: '/qml/quantum-computing',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QC.svg',
        alt: '',
        title: 'Quantum \n Computing',
        description:
          'Learn how universal computation can be achieved using quantum-mechanical systems.',
      },
      {
        link: '/qml/quantum-machine-learning',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QML.svg',
        alt: '',
        title: 'Quantum Machine Learning',
        description:
          'Find out how the principles of quantum computing and machine learning can be united to create something new.',
      },
      {
        link: '/qml/quantum-chemistry',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QChem.svg',
        alt: '',
        title: 'Quantum \n Chemistry',
        description:
          'Study the properties of molecules and materials using quantum computers and algorithms.',
      },
    ],
  },
  see_all_content: {
    text: 'See all content',
    link: '#all-content',
  },
  spotlight_section: {
    announcements: [
      {
        title: 'DEMO: YOUR GUIDE TO PENNYLANE IF YOU KNOW QISKIT',
        description:
          'Explore how our platform’s benefits, tools, and plugins seamlessly allow you to keep your Qiskit work and learn about PennyLane.',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_PLifYouKnowQiskit.png',
        image_right_aligned: false,
        alt: '',
        links: [
          {
            link_text: 'Check it out!', // Please restrict to 30 characters including spaces
            link: '/qml/demos/tutorial_guide_to_pennylane_knowing_qiskit/',
          },
      //     {
      //       link_text: 'Prep with the new Codebook', // Please restrict to 30 characters including spaces
      //       link: '/codebook/',
      //     },
      ],
        colours: {
          border: '#CC00CC',
          background: '#FDFAFD',
          title: '#550055',
          description: '#012343',
          link: '#550055',
        },
      },
      {
        title: 'NEW PENNYLANE CODEBOOK',
        description:
          'Meet the new Codebook! Learn quantum computing with PennyLane—the leading tool for programming quantum computers.',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/spotlights/Spotlight_CodebookLaucnh_2024-04-30.png',
        image_right_aligned: true,
        alt: '',
        links: [
          {
            link_text: 'Start learning now', // Please restrict to 30 characters including spaces
            link: '/codebook/',
          },
          {
            link_text: 'Check out the blog post', // Please restrict to 30 characters including spaces
            link: '/blog/2024/05/pennylane_codebook_makeover/',
          },
        ],
        colours: {
          border: '#0274DE',
          background: '#F7FAFC',
          title: '#0271D9',
          description: '#002445',
          link: '#0271D9',
        },
      },  
    ],
  },
  featured_demos: {
    title: 'Featured demos',
    description:
      'Take a deeper dive into quantum computing by exploring cutting-edge algorithms using PennyLane and quantum hardware.',
    tags_description:
      'Choose a category, or have a look at [demos made by our community](/qml/demos_community/).',
  },
  all_content: {
    title: 'All content',
    cards: [
      {
        title: 'Demos',
        description:
          'Dive into the code and see how to apply cutting-edge algorithms and techniques with PennyLane.',
        link: '/qml/demonstrations',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/demos_icon.png',
      },
      {
        title: 'Challenges',
        description:
          'Get hands-on experience with quantum computing in PennyLane using our exclusive coding challenges.',
        link: '/challenges',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/challenges_icon.png',
      },
      {
        title: 'Codebook',
        description:
          'Use PennyLane to explore various quantum computing topics in the PennyLane Codebook.',
        link: '/codebook/',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/codebook_icon.png',
      },
      {
        title: 'Videos',
        description:
          'Follow along with our experts and explore ideas in quantum computing, quantum machine learning, and quantum chemistry.',
        link: '/qml/videos',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/videos_icon.png',
      },
      {
        title: 'Blog',
        description:
          'Keep up with the newest PennyLane features and events, while learning the latest in quantum programming from experts.',
        link: '/blog/',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/Blog_Icon.png',
      },
      {
        title: 'Glossary',
        description:
          'A growing glossary of key concepts for quantum computing, quantum machine learning, and quantum chemistry.',
        link: '/qml/glossary',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/glossary_icon.png',
      },
    ],
  },
  get_started: {
    title: 'Start building with PennyLane',
    /* Buttons -
        - Please limit button text to 25 characters including spaces.
    */
    buttons: [
      {
        text: 'Install PennyLane',
        link: '/install',
      },
      {
        text: 'Explore demos',
        link: '/qml/demonstrations',
      },
    ],
  },
}
