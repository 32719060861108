import DemosSearchInput from '../../components/DemosSearchInput/DemosSearchInput'
import TagComponent from '../../components/TagComponent/TagComponent'
import {
  ContentType,
  sortParams,
} from '../../context/GenericSearchContext/GenericSearchContext.constants'
import { createSearchPageRoute } from '../../utils/url_helpers'
import MarkdownText from '../MarkdownText/MarkdownText'

export interface IDemoSearchAndTagProps {
  description?: string | JSX.Element
  demosCategories?: {
    title: string
    urlFragment: string
    description: string
  }[]
}

const DemoSearchAndTags = ({
  description,
  demosCategories,
}: IDemoSearchAndTagProps) => {
  return (
    <section className="DemoSearchAndTags">
      <DemosSearchInput />
      <div className="DemoSearchAndTags__container">
        {description && typeof description === 'string' ? (
          <MarkdownText
            className="DemoSearchAndTags__description"
            text={description}
          />
        ) : (
          description
        )}
        {demosCategories && (
          <div className="Tags__wrapper flex flex-wrap">
            <TagComponent text="All Demos" link="/search" />
            {demosCategories?.map((demo) => (
              <TagComponent
                key={demo.title}
                text={demo.title}
                link={createSearchPageRoute({
                  category: demo.title,
                  contentType: ContentType.DEMO,
                  sort: sortParams.PUBLICATION_DATE_DESC,
                })}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default DemoSearchAndTags
